<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12 pt-0 pb-2">
          <h1>
            Job # {{ quotation.jobNumber }}
            <span class="float-right">Add BOQs</span>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 pt-0 pb-0">
          <p>
            <router-link
              custom v-slot="{ navigate }"
              :to="`/live-jobs/overview/${quotation.id}`">
              <v-btn
                small
                @click="navigate"
                @keypress.enter="navigate"
                role="link"
                class="relative"
                style="top:-3px">
                  Save job
              </v-btn>
            </router-link>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12">
          <label>Please select minimum charges for this quotation</label>
          &nbsp;
          <v-btn
            @click="showMinimumCharges = false"
            v-if="showMinimumCharges === true"
            fab
            small>
            <v-icon>mdi-chevron-up-circle</v-icon>
          </v-btn>
          <v-btn
            @click="showMinimumCharges = true"
            v-else
            fab
            small>
            <v-icon>mdi-chevron-down-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table v-if="showMinimumCharges === true" dense>
        <tbody>
          <tr
            v-for="(charge, index) in minimumCharges"
            :key="index">
            <td>
                <input
                  v-if="charge.value"
                  checked
                  @change="addMinCharge($event, index)"
                  type="checkbox"  />
                <input
                  v-else
                  @change="addMinCharge($event, index)"
                  type="checkbox"  />
            </td>
            <td>
              {{ charge.description }}
            </td>
            <td>
              <v-text-field
                @keyup="updateMinimumChargeValueDebounced($event, index)"
                class="sterling-input"
                type="number"
                min="0"
                step="0.01"
                :value="charge.charge / 100" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-row class="pt-2">
        <v-col class="col-12">
          <h3 class="text-right">Minimum charge total: {{ minChargeTotal | priceInPounds | currency }}</h3>
        </v-col>
      </v-row>
      <v-row class="pt-5" v-if="quotation.quotationLines.length > 0">
        <v-col class="col-12">
          <h4>
            Current BOQs
            <v-btn
              @click="openNameSection()"
              class="float-right"
              x-small>Add Section</v-btn>
            <v-btn
              @click="openEditSection()"
              class="float-right mr-1"
              x-small>Edit Sections</v-btn>
          </h4>
          <v-simple-table
            fixed-header
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Quantity</th>
                  <th class="text-left">BOQ</th>
                  <th class="text-left">Material</th>
                  <th class="text-left">Colour</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Section</th>
                  <th class="text-right">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, index) in quotation.quotationLines" :key="index">
                  <td>{{ line.quantity }}</td>
                  <td>{{ line.boq }}</td>
                  <td>{{ line.material }}</td>
                  <td>{{ line.colour }}</td>
                  <td>{{ line.price | priceInPounds | currency }}</td>
                  <td style="width:300px">
                    <v-select
                      @change="saveSection(index)"
                      :items="sections"
                      item-text="description"
                      item-value="id"
                      v-model="line.sectionId"
                    ></v-select>
                  </td>
                  <td class="text-right">
                    <v-btn
                      @click="editBoq(index)"
                      x-small>Edit</v-btn>
                    &nbsp;
                    <v-btn
                      @click="removeBoq(index)"
                      x-small>Remove</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row class="pt-2">
        <v-col class="col-12">
          <h3 class="text-right">BOQ total: {{ boqTotal | priceInPounds | currency }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12">
          <v-tabs
            v-model="boqList"
            color="orange darken-2"
          >
            <v-tab>
              All BOQs
            </v-tab>
            <v-tab>
              Search BOQs
            </v-tab>
            <v-tab>
              Enter BOQ
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="boqList === 0" class="col-12">
          <v-simple-table
            fixed-header
            height="450px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Description</th>
                  <th class="text-left">Unit</th>
                  <th class="text-left">Standard cost</th>
                  <th class="text-right">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(boq, index) in allBoqs" :key="index">
                  <td @click="selectBoq('all', index)" class="link">{{ boq.description }}</td>
                  <td>{{ boq.units }}</td>
                  <td>{{ boq.standard_cost | priceInPounds | currency }}</td>
                  <td>
                    <v-btn
                      @click="selectBoq('all', index)"
                      x-small>Select</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col v-if="boqList === 1" class="col-12">
          <v-row>
            <v-col class="col-3">
              <v-text-field
                label="Type BOQ name"
                @keyup="lookUpBoqsDebounced"
                v-model="searchTerm"></v-text-field>
            </v-col>
          </v-row>
          <v-simple-table
            fixed-header
            height="450px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Description</th>
                  <th class="text-left">Unit</th>
                  <th class="text-left">Standard cost</th>
                  <th class="text-right">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(boq, index) in foundBoqs" :key="index">
                  <td @click="selectBoq('found', index)" class="link">{{ boq.description }}</td>
                  <td>{{ boq.units }}</td>
                  <td>{{ boq.standard_cost | priceInPounds | currency }}</td>
                  <td>
                    <v-btn
                      @click="selectBoq('found', index)"
                      x-small>Select</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col v-if="boqList === 2" class="col-12 pl-7 pr-7 pb-10">
          <v-row>
            <v-col class="col-12">
              <h4>Enter a BOQ</h4>
            </v-col>
          </v-row>
          <v-row v-if="message.length > 0">
            <v-col>
              <p class="text-red">
                {{ message }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="quotation.department === 3">
              <v-select
                :items="departments"
                label="Department"
                item-value="id"
                item-text="name"
                v-model="newBoq.department_id"
                ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newBoq.description"
                label="Description"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
              v-model="newBoq.unit"
                label="Unit"></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="newBoq.material"
                :items="materials"
                item-value="value"
                item-text="text"
                label="Material"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
              v-model="newBoq.cost"
                type="number"
                min="0"
                step="0.01"
                label="Cost"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Colour"
                clearable
                @click:clear="exactMatch = '', fuzzyMatch = [], quotationLine.colour = ''"
                :class="{ 'text-light-grey': newBoq.colour.length === 0 }"
                @keyup="findColour"
                v-model="colourTerm"></v-text-field>
              <div 
                v-if="fuzzyMatch.length"
                class="relative">
                <v-list dense class="boxed-list">
                  <v-list-item class="pl-0">
                    <span>
                      Please click a colour to select
                    </span>
                  </v-list-item>
                  <v-list-item
                    class="pl-0"
                    v-if="exactMatch">
                    <span
                      class="link"
                      @click="selectExactColour('newBoq')">
                      {{ exactMatch.text }}
                    </span>
                  </v-list-item>
                  <v-list-item
                    class="pl-0"
                    v-for="(match, index) in fuzzyMatch"
                    :key="index">
                    <span
                      class="link"
                      @click="selectFuzzyColour('newBoq', index)">
                      {{ match.text }}
                    </span>
                  </v-list-item>
                </v-list>
                </div>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newBoq.quantity"
                label="Quantity"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newBoq.price"
                label="Price"
                type="number"
                min="0"
                step="0.01"></v-text-field>
            </v-col>
            <v-col>
              <v-btn block class="btn mt-3" @click="saveNewBoq">Add BOQ</v-btn>
            </v-col>
          </v-row>
          <div style="padding-top:200px">&nbsp;</div>
        </v-col>
      </v-row>
      <div
        class="modal"
        v-if="chooseColour">
        <v-icon
          class="close"
          @click="chooseColour = false"
        >mdi-close-circle</v-icon>
          <v-row>
            <v-col class="col-12">
              <label>Details - {{ quotationLine.boq.description }} @cost ea: {{ quotationLine.boq.standard_cost | priceInPounds | currency }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="quotation.department === 3">
              <label>Department</label>
              <v-select
                :items="departments"
                item-value="id"
                item-text="name"
                v-model="quotationLine.department_id"
                ></v-select>
            </v-col>
            <v-col>
              <label>Colour</label>
              <v-text-field
                clearable
                @click:clear="exactMatch = '', fuzzyMatch = [], quotationLine.colour = ''"
                :class="{ 'text-light-grey': quotationLine.colour.length === 0 }"
                @keyup="findColour"
                v-model="colourTerm"></v-text-field>
              <div 
                v-if="fuzzyMatch.length"
                class="relative">
                <v-list dense class="boxed-list">
                  <v-list-item class="pl-0">
                    <span>
                      Please click a colour to select
                    </span>
                  </v-list-item>
                  <v-list-item
                    class="pl-0"
                    v-if="exactMatch">
                    <span
                      class="link"
                      @click="selectExactColour('qLine')">
                      {{ exactMatch.text }}
                    </span>
                  </v-list-item>
                  <v-list-item
                    class="pl-0"
                    v-for="(match, index) in fuzzyMatch"
                    :key="index">
                    <span
                      class="link"
                      @click="selectFuzzyColour('qLine', index)">
                      {{ match.text }}
                    </span>
                  </v-list-item>
                </v-list>
                </div>
            </v-col>
            <v-col>
              <label>Material</label>
              <v-autocomplete
                :items="materials"
                v-model="quotationLine.material"></v-autocomplete>
            </v-col>
            <v-col>
              <label>Quantity</label>
              <v-text-field
                type="number"
                step="0.1"
                min="0"
                v-model="quotationLine.quantity"></v-text-field>
            </v-col>
            <v-col>
              <label>Price</label>
              <v-text-field
                type="number"
                step="0.01"
                min="0"
                v-model="quotationLine.price"></v-text-field>
            </v-col>
            <v-col>
              <div class="pt-9 text-right">
                <v-btn @click="addLine" block>Add</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="message.length > 0">
            <v-col>
              <p class="text-red">{{ message }}</p>
            </v-col>
          </v-row>
      </div>
      <div v-if="nameSection === true" class="modal">
        <v-icon
          class="close"
          @click="nameSection = false"
        >mdi-close-circle</v-icon>
        <v-row>
          <v-col>
            <label>New Section title</label>
            <v-text-field
              ref="newSectionTitle"
              v-model="sectionTitle"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="saveSectionTitle">Save</v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-if="editSection === true" class="modal">
        <v-icon
          class="close"
          @click="editSection = false"
        >mdi-close-circle</v-icon>
        <v-row>
          <v-col>
            <h4>Sections</h4>
          </v-col>
        </v-row>
        <v-row v-for="(section, index) in sections" :key="index">
          <v-col>
            <v-text-field v-model="section.description"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="saveSectionEdits">Save</v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-if="lineInEdit.boq" class="modal">
        <v-icon
          class="close"
          @click="lineInEdit = {}"
        >mdi-close-circle</v-icon>
        <div class="row">
          <v-col class="col-3">
            <label>Colour</label>
            <v-autocomplete
              v-model="lineInEdit.colour"
              :items="colours"></v-autocomplete>
          </v-col>
          <v-col class="col-3">
            <label>Material</label>
            <v-autocomplete
              v-model="lineInEdit.material"
              :items="materials"></v-autocomplete>
          </v-col>
          <v-col class="col-3">
            <label>Quantity</label>
            <v-text-field v-model="lineInEdit.quantity" type="number" step="0.1" min="0"></v-text-field>
          </v-col>
          <v-col class="col-3">
            <label>Price</label>
            <v-text-field v-model="lineInEdit.price" type="number" step="0.01" min="0"></v-text-field>
          </v-col>
          <v-col class="col-3">
            <div class="pt-12">
              <v-btn @click="saveLineInEdit">Save changes</v-btn>
            </div>
          </v-col>
        </div>
      </div>
    </v-container>
  </template>
  
  <script>
  import axios from '../../axios';
  
  export default {
    name: 'AddLines',
    data() {
      return {
        departments: [
          { id:1, name:'Paint' },
          { id:2, name:'Thermo'},
        ],
        showMinimumCharges: true,
        quotation: {
          minChargeDescription: '1',
          quotationLines: [],
        },
        minimumCharges: [],
        boqList: 0,
        allBoqs: [],
        foundBoqs: [],
        searchTerm: '',
        quotationLine: {
          colour: '',
          boq: {},
          price: null,
          quantity: null,
          section: 1,
          department_id: 0,
          material: 0,
        },
        quotationLines: [],
        colours: [],
        chooseColour: false,
        sections: [],
        currentSection: 1,
        nameSection: false,
        editSection: false,
        sectionTitle: '',
        lineInEdit: {},
        colourTerm: '',
        exactMatch: {},
        fuzzyMatch: [],
        newBoq: {
          colour: '',
          cost: null,
          department_id: null,
          description: null,
          material: null,
          price: null,
          quantity: null,
          unit: null,
        },
        message: '',
        materials: [],
      };
    },
    computed: {
      token() {
        return this.$store.state.token;
      },
      userid() {
        return this.$store.state.userid;
      },
      minChargeTotal() {
        let total = 0;
        for (let i = 0; this.minimumCharges.length > i; i += 1) {
          if (this.minimumCharges[i].value === true) {
            total += this.minimumCharges[i].charge;
          }
        }
        return total;
      },
      boqTotal() {
        let total = 0;
        for (let i = 0; this.quotation.quotationLines.length > i; i += 1) {
          total += this.quotation.quotationLines[i].quantity * this.quotation.quotationLines[i].price;
        }
        return total;
      },
    },
    watch: {
      currentSection(value) {
        this.quotationLine.section = value;
      },
      newBoq: {
        handler() {
          this.message = '';
        },
        deep: true
      },
    },
    methods: {
      findColour() {
        const term = this.colourTerm;
        let exact = '';
        let fuzzy = [];
        for (let i = 0; this.colours.length > i; i += 1) {
          if (this.colours[i].text.toLowerCase() === term.toLowerCase()) exact = this.colours[i];
          if (this.colours[i].text.toLowerCase().includes(term.toLowerCase())) fuzzy.push(this.colours[i]);
        }
        this.exactMatch = exact;
        this.fuzzyMatch = fuzzy;
      },
      selectExactColour(entType) {
        if (entType === 'qLine') {
          this.quotationLine.colour = this.exactMatch.value;
        }
        if(entType === 'newBoq') {
          this.newBoq.colour = this.exactMatch.value;
        }
        this.colourTerm = this.exactMatch.text;
        this.exactMatch = '';
        this.fuzzyMatch = [];
      },
      selectFuzzyColour(entType, index) {
        const match = this.fuzzyMatch[index].value;
        if (entType === 'qLine') {
          this.quotationLine.colour = match;
        }
        if(entType === 'newBoq') {
          this.newBoq.colour = match;
        }
        this.colourTerm = this.fuzzyMatch[index].text;
        this.exactMatch = '';
        this.fuzzyMatch = [];
      },
      addMinCharge(event, index) {
        const postData = {};
        postData.quotation = this.quotation.id;
        postData.addMinimumCharge = event.target.checked;
        postData.minimumCharge = this.minimumCharges[index];
        axios.post(`/quotations/saveMinimumCharge.json?token=${this.token}`, postData)
        .then((response) => {
          this.quotation.minChargeAmount = response.data.minChargeAmount;
          this.quotation.minChargeDescription = this.minChargeDescription;
          if (postData.addMinimumCharge === true) {
            this.minimumCharges[index].value = true;
          } else {
            this.minimumCharges[index].value = false;
          }
        });
      },
      updateMinChargeValue(event, index) {
        const postData = {};
        postData.amount = event.target.value;
        postData.minimumCharge = this.minimumCharges[index];
        postData.quotation = this.quotation.id;
        axios.post(`/minimumChargesQuotations/saveMinimumChargeAmount.json?token=${this.token}`, postData)
        .then((response) => {
          this.quotation.minChargeAmount = response.data.minChargeAmount;
          this.minimumCharges[index].charge = (event.target.value * 100);
        });
      },
      updateMinimumChargeValueDebounced(event, index) {
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => {
          this.updateMinChargeValue(event, index);
        }, 600);
      },
      openNameSection() {
        this.nameSection = true;
        this.editSection = false;
        this.$nextTick(() => this.$refs.newSectionTitle.focus());
      },
      openEditSection() {
        this.editSection = true;
        this.nameSection = false;
      },
      validateNewBoq() {
        return new Promise((resolve, reject) => {
          if (
            this.newBoq.colour
            && this.newBoq.cost
            && this.newBoq.department_id
            && this.newBoq.description
            && this.newBoq.material
            && this.newBoq.price
            && this.newBoq.quantity
            && this.newBoq.unit
          ) {
            resolve();
          } else {
            reject('Please complete all fields');
          }
        });
      },
      saveNewBoq() {
        if (this.quotation.department !== 3) this.newBoq.department_id = this.quotation.department;
        const postData = {};
        postData.newBoq = this.newBoq;
        postData.quotation = this.$route.params.id;
        postData.user_id = this.$store.state.userid;
        this.validateNewBoq()
        .then(() => {
          axios.post(`/quotationLines/addNewBoq.json?token=${this.token}&userid=${this.userid}`, postData)
          .then((response) => {
            this.quotation = response.data;
            this.colourTerm = '';
            this.exactMatch = '';
            this.fuzzyMatch = [];
            this.newBoq = {
              colour: '',
              cost: null,
              department_id: null,
              description: null,
              material: null,
              price: null,
              quantity: null,
              unit: null,
            };
          });
        },
        (error) => {
          this.message = error;
        });
      },
      validateLine() {
        return new Promise((resolve, reject) => {
          if (
            this.quotationLine.colour > 0
            && this.quotationLine.price
            && this.quotationLine.quantity
          ) {
            resolve();
          } else {
            reject('Please complete all fields');
          }
        });
      },
      addLine() {
        const postData = {};
        postData.line = this.quotationLine;
        if (this.quotation.department !== 3) postData.line.boq.department_id = this.quotation.department;
        postData.quotation = this.$route.params.id;
        postData.user_id = this.$store.state.userid;
        this.validateLine()
        .then(() => {
          axios.post(`/quotationLines/create.json?token=${this.token}`, postData)
            .then((response) => {
              this.chooseColour = false;
              this.quotation = response.data;
              this.quotationLine = {
                boq: {},
                price: 0,
                quantity: 0,
                colour: '',
                material: 0,
              };
              this.sections = response.data.sectionList;
              this.foundBoqs = [];
              this.searchTerm = '';
              this.colourTerm = '';
              this.exactMatch = '';
              this.fuzzyMatch = [];
            });
          },
          (error) => {
            this.message = error;
          });
      },
      saveSectionEdits() {
        axios.post(`/sections/updateMany.json?token=${this.token}`, this.sections)
        .then(() => {
          this.editSection = false;
        });
      },
      saveSection(index) {
        const line = this.quotation.quotationLines[index];
        axios.post(`/quotationLines/updateSection.json?token=${this.token}`, line);
      },
      saveLineInEdit() {
        axios.post(`/quotationLines/update.json?token=${this.token}`, this.lineInEdit)
          .then((response) => {
            this.quotation = response.data;
            this.lineInEdit = {};
          });
      },
      editBoq(index) {
        const boq = this.quotation.quotationLines[index];
        this.lineInEdit = {};
        this.lineInEdit.lineIndex = index;
        this.lineInEdit.boq = boq;
        this.lineInEdit.price = (boq.price / 100);
        this.lineInEdit.quantity = boq.quantity;
        this.lineInEdit.material = this.materials.filter(material => material.text === boq.material)[0].value;
        this.lineInEdit.colour = this.colours.filter(colour => colour.text === boq.colour)[0].value;
      },
      saveSectionTitle() {
        const postData = {};
        postData.sectionTitle = this.sectionTitle;
        postData.quotation = this.$route.params.id;
        axios.post(`/sections/create.json?token=${this.token}`, postData)
          .then((response) => {
            this.quotation = response.data;
            this.nameSection = false;
            this.currentSection += 1;
            this.sectionTitle = '';
            this.sections = response.data.sectionList;
          });
      },
      selectBoq(type, index) {
        let boq = {};
        if (type === 'all') boq = this.allBoqs[index];
        if (type === 'found') boq = this.foundBoqs[index];
        this.quotationLine.boq = boq;
        this.quotationLine.price = (boq.standard_cost / 100);
        this.chooseColour = true;
      },
      changeBoq() {
        this.foundBoqs = [];
        this.quotationLine.boq = {};
      },
      lookUpBoqsDebounced() {
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => {
          this.lookUpBoqs();
        }, 250);
      },
      lookUpBoqs() {
        if (this.searchTerm.length > 2) {
          const postData = { searchTerm: this.searchTerm };
          axios.post(`/boqs/lookup.json?token=${this.token}`, postData)
          .then((response) => {
            this.foundBoqs = response.data;
          });
        }
      },
      removeBoq(index) {
        const line = this.quotation.quotationLines[index];
        axios.post(`/quotationLines/remove.json?token=${this.token}`, line)
          .then(() => {
            this.quotation.quotationLines.splice(index, 1);
          });
      },
      getBoqs() {
        axios.get(`/boqs/getAllBoqs.json?token=${this.token}`)
          .then((response) => {
            this.allBoqs = response.data;
            this.getColours();
          });
      },
      getColours() {
        axios.get(`/colours/getAll.json?token=${this.token}`)
          .then((response) => {
            this.colours = response.data;
            this.getMaterials();
          });
      },
      getMaterials() {
        axios.get(`/materials/getAll.json?token=${this.token}`)
          .then((response) => {
            this.materials = response.data;
          });
      },
      getQuotation() {
        const quotationId = this.$route.params.id;
        axios.get(`/quotations/getSingle/${quotationId}.json?token=${this.token}`)
          .then((response) => {
            this.quotation = response.data;
            this.sections = response.data.sectionList;
            if (!response.data.quotationLines) this.quotation.quotationLines = [];
            if (response.data.sections) {
              this.currentSection = response.data.sections.length;
            } else {
              this.currentSection = 1;
            }
            this.getMinimumCharges();
          });
      },
      getMinimumCharges() {
        axios.get(`/minimumCharges/getAll.json?token=${this.token}`)
          .then((response) => {
            const minCharges = response.data.minimumCharges;
            const charges = [];
            for(let i = 0; minCharges.length > i; i += 1) {
              const charge = {};
              charge.id = minCharges[i].id;
              charge.charge = minCharges[i].charge;
              charge.description = minCharges[i].description;
              if (this.quotation.minimumCharges) {
                for (let x = 0; this.quotation.minimumCharges.length > x; x += 1) {
                  if (this.quotation.minimumCharges[x].minChargeId === minCharges[i].id) {
                    charge.value = true;
                    charge.charge = this.quotation.minimumCharges[x].minChargeValue;
                    x = this.quotation.minimumCharges.length;
                  } else {
                    charge.value = false;
                  }
                }
              }
              charges.push(charge);
            }
            this.minimumCharges = charges;
          });
      },
    },
    mounted() {
      this.getQuotation();
      this.getBoqs();
    },
  }
  </script>
  